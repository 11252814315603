<template>
  <div
    class="main"
    v-if="havePermission"
    :style="
      `${$route.name == 'detailPage' ? 'height: calc(100vh - 170px);' : ''}`
    "
  >
    <div
      class="structureTree"
      :style="treeStyle"
      v-loading="loadinDetail"
      v-if="$route.name == 'detailPage' && isArticle"
    >
      <transition name="tree">
        <el-tree
          v-show="showTree"
          :data="articleTree"
          :props="defaultProps"
          :highlight-current="true"
          @node-click="handleNodeClick"
          auto-expand-parent
          :default-expanded-keys="treeExpandedKeys"
          :expand-on-click-node="false"
          icon-class="el-icon-arrow-right"
          node-key="menuId"
          ref="tree"
        >
        </el-tree>
      </transition>
      <span class="tree_Collapse" @click="handleCollapse('1')">
        <svg-icon icon-class="leftCollapse" v-if="treeCollapse"></svg-icon>
        <svg-icon icon-class="rightCollapse" v-else></svg-icon>
      </span>
    </div>
    <div
      class="structureTree"
      :style="treeStyle"
      v-loading="loadinDetail"
      v-else-if="$route.name == 'detailPage' && !isArticle"
    >
      <transition name="tree">
        <el-tree
          v-show="showTree"
          :data="articleTree"
          :props="defaultProps2"
          :highlight-current="true"
          @node-click="handleNodeClick"
          auto-expand-parent
          :default-expanded-keys="treeExpandedKeys"
          :expand-on-click-node="false"
          icon-class="el-icon-arrow-right"
          node-key="id"
          ref="tree"
        >
        </el-tree>
      </transition>
      <span class="tree_Collapse" @click="handleCollapse('1')">
        <svg-icon icon-class="leftCollapse" v-if="treeCollapse"></svg-icon>
        <svg-icon icon-class="rightCollapse" v-else></svg-icon>
      </span>
    </div>
    <div
      class="content"
      v-loading="loadinDownload"
      :element-loading-text="loadingText"
      v-if="!handover"
      ref="content"
    >
      <div class="content_info">
        <div class="content_info_title">
          <p>
            {{ detailData.title }}
            <span title="Recommend" v-if="detailData.recommendLevel == 1">
              <svg-icon icon-class="hot"></svg-icon>
            </span>
          </p>
        </div>
        <div class="content_info_data">
          <span class="postTime"
            ><span>Post&nbsp;</span
            >{{
              detailData.releaseTime
                ? moment(detailData.releaseTime).format("YYYY.MM.DD")
                : "--"
            }}</span
          >
          <span class="downloads">
            <svg-icon icon-class="downloads" />{{ detailData.downloadsNum }}
          </span>
          <span class="hits">
            <svg-icon icon-class="hits" />{{ detailData.viewsNum }}
          </span>
        </div>
        <div
          :class="[
            productVersionName.length > 0
              ? 'applicabletagName'
              : 'applicabletagName borderBottom',
          ]"
          v-if="tagName !== null"
        >
          <p :title="tagName">{{ tagName.split(",").join(", ") }}</p>
        </div>
        <div
          :class="[
            productVersionName.length > 0
              ? 'applicableVersion borderBottom'
              : 'applicableVersion',
          ]"
          v-if="productVersionName.length > 0"
        >
          <span class="version_title">Version:</span>
          <span :title="productVersionName.join(', ')" class="version_item">{{
            productVersionName.join(", ")
          }}</span>
        </div>
        <div class="content_info_text">
          <p v-html="detailData.content" id="markdownText" v-viewer></p>
        </div>
        <div class="jumpArticleMsg" v-if="jumpLoading">
          <p>{{ isNoarticleMsg }}</p>
        </div>
        <div class="content_info_filedown" v-if="isArticle">
          <div class="filedown_item" v-for="val in fileData" :key="val.id">
            <div class="iconImg">
              <svg-icon
                icon-class="pdf"
                v-if="val.fileType == 'pdf' || val.fileType == 'PDF'"
              ></svg-icon>
              <svg-icon
                icon-class="mp4"
                v-else-if="val.fileType == 'mp4' || val.fileType == 'MP4'"
              ></svg-icon>
              <svg-icon
                icon-class="text"
                v-else-if="val.fileType == 'txt' || val.fileType == 'TXT'"
              ></svg-icon>
              <svg-icon
                icon-class="img"
                v-else-if="
                  val.fileType == 'png' ||
                    val.fileType == 'jpg' ||
                    val.fileType == 'PNG' ||
                    val.fileType == 'JPG'
                "
              ></svg-icon>
              <svg-icon
                icon-class="doc"
                v-else-if="
                  val.fileType == 'doc' ||
                    val.fileType == 'docx' ||
                    val.fileType == 'DOC' ||
                    val.fileType == 'DOCX'
                "
              ></svg-icon>
              <svg-icon
                icon-class="xls"
                v-else-if="
                  val.fileType == 'xls' ||
                    val.fileType == 'xlsx' ||
                    val.fileType == 'XLS' ||
                    val.fileType == 'XLSX'
                "
              ></svg-icon>
              <svg-icon
                icon-class="ppt"
                v-else-if="
                  val.fileType == 'ppt' ||
                    val.fileType == 'pptx' ||
                    val.fileType == 'PPT' ||
                    val.fileType == 'PPTX'
                "
              ></svg-icon>
              <svg-icon
                icon-class="zip"
                v-else-if="
                  val.fileType == 'zip' ||
                    val.fileType == '7z' ||
                    val.fileType == 'rar' ||
                    val.fileType == 'ZIP' ||
                    val.fileType == '7Z' ||
                    val.fileType == 'RAR'
                "
              ></svg-icon>
            </div>
            <div class="fileName" @click="handleDownfile(val)">
              {{ val.fileName }}
            </div>
            <div class="iconDonw" @click="handleDownfile(val)">
              <svg-icon icon-class="downloads"></svg-icon>
            </div>
          </div>
        </div>
        <div class="evaluate" v-if="showEvaluate">
          <p class="Feedback">Feedback</p>
          <div class="evaluate_item">
            <p
              class="good"
              @click="haedleEstimate('good')"
              :class="{ active: commentType == 0 }"
            >
              <svg-icon icon-class="good" /> Good
            </p>
            <p
              class="bad"
              @click="haedleEstimate('bad')"
              :class="{ active: commentType == -1 }"
            >
              <svg-icon icon-class="bad" /> Bad
            </p>
          </div>
          <transition name="estimate">
            <div class="estimate_dialog" v-show="showestimateDialog">
              <p class="estimate_dialog_title">Your Suggestion</p>
              <textarea
                class="estimate_dialog_text"
                v-model="commentContent"
                placeholder="Welcome to leave your valuable feedback"
              ></textarea>
              <el-button
                v-loading="sendLoading"
                type="primary"
                class="estimate_dialog_btn"
                @click="sendEstimate"
                >Send</el-button
              >
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div
      class="relevantLink"
      v-if="isArticle"
      :style="relevantLinkStyle"
      v-loading="loadinDetail"
    >
      <transition name="relevant">
        <div class="relevantLink_box" v-show="showrelevantLink">
          <div
            class="relevantLink_info"
            v-if="relevantLink && relevantLink.length > 0"
          >
            <div
              class="info_item"
              v-for="val in relevantLink"
              :key="val.articleId"
            >
              <div
                class="info_item_title"
                @click="handleRelevantLink(val.articleId)"
              >
                {{ val.title }}
                <span title="Recommend" v-if="val.recommendLevel == 1">
                  <svg-icon icon-class="hot"></svg-icon>
                </span>
              </div>
              <div class="info_item_data">
                <span class="postTime">
                  <span>Post&nbsp;</span
                  >{{
                    val.releaseTime
                      ? moment(val.releaseTime).format("YYYY.MM.DD")
                      : "--"
                  }}</span
                >
                <span class="downloads">
                  <svg-icon icon-class="downloads"></svg-icon
                  >{{ val.downloadsNum }}
                </span>
                <span class="pageview">
                  <svg-icon icon-class="hits"></svg-icon>{{ val.viewsNum }}
                </span>
              </div>
            </div>
          </div>
          <div class="relevantLink_info" v-else>
            <div class="iconBox">
              <svg-icon icon-class="nodate"></svg-icon>
              <p class="nodata">No Data</p>
            </div>
          </div>
        </div>
      </transition>
      <span class="relevantLink_Collapse" @click="handleCollapse('2')">
        <svg-icon icon-class="rightCollapse" v-if="reCollapse"></svg-icon>
        <svg-icon icon-class="leftCollapse" v-else></svg-icon>
      </span>
    </div>
  </div>
  <div class="main" v-else>
    <p class="noPermission">{{ permissionInfo }}</p>
  </div>
</template>
<script>
import { Base64, encode } from "js-base64";
import moment from "moment";
import axios from "axios";
import {
  getArticleDetail,
  getVersionSwitch,
  sendComment,
  downloadFile,
  addDownloadsNum,
  getProductVersionList,
  sendHtml,
  getCatalogueByAticle,
  getById,
  jumpNextChapter,
} from "../../api/common";
// import $ from "jquery";
// import { loginPp } from "../../api/login";
export default {
  name: "detailMain",
  data() {
    return {
      handover: false,
      havePermission: true,
      showEvaluate: false,
      loadinDetail: false,
      loadinDownload: false,
      loadingText: "",
      sendLoading: false,
      showestimateDialog: false,
      isArticle: false,
      treeCollapse: false,
      reCollapse: false,
      showTree: true,
      showrelevantLink: true,
      jumpLoading: false,
      commentContent: "",
      commentType: 2,
      isNoarticleMsg: "",
      //结构树
      articleTree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      defaultProps2: {
        children: "childList",
        label: "name",
      },
      treeExpandedKeys: [],
      //语言下拉框
      // languageValue: "",
      // languageOption: [],
      //版本下拉框
      // versionValue: "",
      // versionOption: [],
      //推荐链接
      relevantLink: [],
      //动态设置结构树和推荐链接的宽
      treeStyle: {
        width: "15%",
      },
      relevantLinkStyle: {
        width: "20%",
      },
      //下载预览文件
      fileData: [],
      detailData: {},
      //跳转传过来的值
      articleData: {},
      // breadcrumbData: [],
      pdfUrl: "",
      permissionInfo: "",
      articleCode: "",
      productVersionName: [],
      tagName: null,
      jumpDownNum: 3,
    };
  },
  methods: {
    //点击结构树
    handleNodeClick(val) {
      this.jumpLoading = false;
      let detailId = val.menuId;
      let detailParId = val.parentId;
      let data = this.articleTree[0];
      if (val.articleType == 0) {
        this.$router.push({
          name: "indexPage",
          params: { data: data, detailId: detailId, detailParId: detailParId },
          query: { module: detailId },
        });
        localStorage.setItem("library_tree", JSON.stringify(data));
      } else {
        this.loadinDetail = true;
        getById(val.id)
          .then((res) => {
            this.detailData = res.data.rows;
            if (res.data.rows.content) {
              this.detailData.content = res.data.rows.content.replace(
                /<div class="xs0">([\s\S]*?)<\/div>/g,
                ""
              );
            } else {
              let params = {
                articleId: this.detailData.articleId,
                id: this.detailData.id,
              };
              jumpNextChapter(params).then((res) => {
                if (res.data.code == 0) {
                  this.detailData.content = res.data.rows.content;
                  this.detailData.id = res.data.rows.id;
                  this.$nextTick(() => {
                    this.$refs.tree?.setCurrentKey(this.detailData.id);
                    this.treeExpandedKeys = [this.detailData.id];
                  });
                } else {
                  this.isNoarticleMsg = res.data.msg;
                  this.jumpLoading = true;
                }
              });
            }
            this.loadinDetail = false;
          })
          .catch(() => {
            this.detailData = [];
            this.loadinDetail = false;
          });
      }
    },
    //跳转倒计时
    jumpDown() {
      let timer = setInterval(() => {
        if (this.jumpDownNum !== 0) {
          this.jumpDownNum--;
        } else {
          clearInterval(timer);
          this.jumpDownNum = 3;
        }
      }, 1000);
    },
    //点击评论状态
    haedleEstimate(val) {
      // let params = {
      //   articleId: this.detailData.articleId,
      //   articleStructure: String(this.detailData.articleSource),
      //   articleTitle: this.detailData.title,
      //   menuId: this.detailData.articleCategory,
      //   commentContent: " ",
      //   commentType: this.commentType,
      // };
      if (val == "good") {
        this.showestimateDialog = false;
        this.commentType = 0;
        setTimeout(() => {
          this.commentType = 2;
        }, 200);
        this.$message({
          message: "Feedback successfully",
          duration: 1000,
          type: "success",
        });

        // params.commentType = this.commentType;
        // sendComment(params)
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch(() => {
        //     this.commentType = 2;
        //   });
      } else {
        this.commentType = this.commentType == -1 ? 2 : -1;
        this.showestimateDialog =
          this.showestimateDialog == true ? false : true;
      }
    },
    //点击打开文件预览
    handlePreview(val) {
      if (
        val.fileType != "mp4" &&
        val.fileType != "MP4" &&
        val.fileType != "zip" &&
        val.fileType != "7z" &&
        val.fileType != "rar"
      ) {
        window.open(
          process.env.VUE_APP_preview_URL +
            "onlinePreview?url=" +
            encodeURIComponent(Base64.encode(val.filePath))
        );
      } else {
        this.handleDownfile(val);
      }
    },
    //发送意见
    sendEstimate() {
      this.sendLoading = true;
      this.commentType = -1;
      let params = {
        articleId: this.detailData.articleId,
        articleStructure: String(this.detailData.articleSource),
        articleTitle: this.detailData.title,
        menuId: this.detailData.articleCategory,
        commentContent: this.commentContent,
        commentType: this.commentType,
      };
      console.log(params);
      sendComment(params)
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.showestimateDialog = false;
            this.sendLoading = false;
            this.$message({
              message: "Feedback successfully",
              duration: 1000,
              type: "success",
            });
          } else if (res.data.code == 500) {
            this.showestimateDialog = false;
            this.sendLoading = false;
            this.$message({
              message: res.msg,
              duration: 1000,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.commentType = 2;
          this.sendLoading = false;
        });
    },
    //获取文章详情
    getDetail(data, keyword) {
      this.loadinDetail = true;
      const parmas = {
        keyword: keyword,
        articleId: data,
      };
      if (this.articleData.articleType == 1) {
        this.havePermission = false;
        getArticleDetail(parmas)
          .then((res) => {
            if (res.data.code == 0) {
              this.detailData = res.data.rows.articleInfo;
              if (res.data.rows.articleInfo.content) {
                this.detailData.content = res.data.rows.articleInfo.content.replace(
                  /<div class="xs0">([\s\S]*?)<\/div>/g,
                  ""
                );
              }
              this.relevantLink = res.data.rows.recommendArticleList;
              this.articleTree = res.data.rows.menuList;
              // this.languageValue = res.data.rows.articleInfo.languageCode;
              // this.languageOption = res.data.rows.languageVoList;
              this.$emit("getLanguage", {
                languageValue: res.data.rows.articleInfo.languageCode,
                languageOption: res.data.rows.languageVoList,
              });

              this.productVersionName = res.data.rows.articleInfo
                .productVersionName
                ? res.data.rows.articleInfo.productVersionName.split(",")
                : [];
              this.tagName = res.data.rows.articleInfo.tagName
                ? res.data.rows.articleInfo.tagName
                : null;
              // this.versionValue = res.data.rows.articleInfo.productVersion;
              // this.versionOption = res.data.rows.productVersionList
              //   ? res.data.rows.productVersionList
              //   : [];
              this.$emit("getVersion", {
                versionValue: res.data.rows.articleInfo.productVersion,
                versionOption: res.data.rows.productVersionList
                  ? res.data.rows.productVersionList
                  : [],
              });
              this.treeExpandedKeys = res.data.rows.treeIdList;
              // this.breadcrumbData = res.data.rows.crumbsList;
              this.$emit("getBreadcrumbData", res.data.rows.crumbsList);
              this.fileData = res.data.rows.fileList;
              this.havePermission = true;
              this.loadinDetail = false;
              if (res.data.rows.menuList.length > 0) {
                this.$nextTick(() => {
                  this.$refs.tree?.setCurrentKey(
                    this.detailData.articleCategory
                  );
                });
              }
            } else if (res.data.code == 2001) {
              localStorage.removeItem("library_token");
              localStorage.removeItem("library_userInfo");
              delete axios.defaults.headers["Authorization"];
              let url = location.href;
              this.$router.push({ name: "login", params: { url: url } });
            } else if (res.data.code == 2003) {
              this.havePermission = false;
              this.permissionInfo =
                "You do not have permission to read this article";
              this.loadinDetail = false;
            } else if (res.data.code == 2002) {
              this.havePermission = false;
              this.permissionInfo =
                "This article does not exist or has been taken down";
              this.loadinDetail = false;
            }
          })
          .catch(() => {
            this.detailData = [];
            this.havePermission = true;
            this.loadinDetail = false;
          });
      } else {
        console.log(parmas, "=============");
        getArticleDetail(parmas)
          .then((res) => {
            if (res.data.code == 0) {
              this.detailData = res.data.rows.articleInfo;
              this.articleTree = res.data.rows.mdCatalogueList;
              // this.languageValue = res.data.rows.articleInfo.languageCode;
              // this.languageOption = res.data.rows.languageVoList;
              this.$emit("getLanguage", {
                languageValue: res.data.rows.articleInfo.languageCode,
                languageOption: res.data.rows.languageVoList,
              });
              this.articleCode = res.data.rows.articleInfo.articleCode;
              // this.versionValue = res.data.rows.articleInfo.productVersion;
              // this.versionOption = res.data.rows.productVersionList
              //   ? res.data.rows.productVersionList
              //   : [];
              this.$emit("getVersion", {
                versionValue: res.data.rows.articleInfo.productVersion,
                versionOption: res.data.rows.productVersionList
                  ? res.data.rows.productVersionList
                  : [],
              });
              // this.breadcrumbData = res.data.rows.crumbsList;
              this.$emit("getBreadcrumbData", res.data.rows.crumbsList);
              var catalogueId;
              if (
                res.data.rows.catalogueId !== "" &&
                res.data.rows.catalogueId !== null
              ) {
                catalogueId = res.data.rows.catalogueId;
              } else {
                catalogueId = this.articleTree.length
                  ? this.articleTree[0].id
                  : "";
              }
              if (catalogueId) {
                getById(catalogueId)
                  .then((res) => {
                    this.detailData = res.data.rows;
                    if (res.data.rows.content) {
                      this.$nextTick(() => {
                        this.$refs.tree?.setCurrentKey(this.detailData.id);
                        this.treeExpandedKeys = [this.detailData.id];
                      });
                      this.detailData.content = res.data.rows.content.replace(
                        /<div class="xs0">([\s\S]*?)<\/div>/g,
                        ""
                      );
                    } else {
                      let params = {
                        articleId: this.detailData.articleId,
                        id: this.detailData.id,
                      };
                      jumpNextChapter(params).then((res) => {
                        if (res.data.code == 0) {
                          this.detailData.content = res.data.rows.content;
                          this.detailData.id = res.data.rows.id;
                          this.$nextTick(() => {
                            this.$refs.tree?.setCurrentKey(this.detailData.id);
                            this.treeExpandedKeys = [this.detailData.id];
                          });
                        } else {
                          this.isNoarticleMsg = res.data.msg;
                          this.jumpLoading = true;
                          this.$nextTick(() => {
                            this.$refs.tree?.setCurrentKey(this.detailData.id);
                            this.treeExpandedKeys = [this.detailData.id];
                          });
                        }
                      });
                    }
                    this.loadinDetail = false;
                  })
                  .catch(() => {
                    this.detailData = [];
                    this.loadinDetail = false;
                  });
              } else {
                this.loadinDetail = false;
              }
            } else if (res.data.code == 2001) {
              localStorage.removeItem("library_token");
              localStorage.removeItem("library_userInfo");
              delete axios.defaults.headers["Authorization"];
              let url = location.href;
              this.$router.push({ name: "login", params: { url: url } });
            } else if (res.data.code == 2003) {
              this.havePermission = false;
              this.permissionInfo =
                "You do not have permission to read this article";
              this.loadinDetail = false;
            } else if (res.data.code == 2002) {
              this.havePermission = false;
              this.permissionInfo =
                "This article does not exist or has been taken down";
              this.loadinDetail = false;
            }
          })
          .catch(() => {
            this.detailData = [];
            this.articleTree = [];
            this.loadinDetail = false;
          });
        //   getCatalogueByAticle(data).then((res) => {
        //   this.articleTree = res.data.rows;
        //   console.log(this.articleTree);
        //   getById(this.articleTree[0].id).then((res) => {
        //     this.detailData = res.data.rows;
        //     this.$nextTick(() => {
        //       this.$refs.tree.setCurrentKey(this.detailData.id);
        //     });
        //     this.loadinDetail = false;
        //   }).catch(()=>{
        //     this.detailData = [];
        //     this.loadinDetail = false;
        //   });
        // }).catch(()=>{
        //   this.detailData = [];
        //   this.articleTree =[];
        //   this.loadinDetail = false;
        // });
        // }
      }
    },
    //点击复制
    handleCopy() {
      var domUrl = document.createElement("input");
      domUrl.value = window.location.href;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        message: "Copy success",
        type: "success",
      });
    },
    //切换语言版本
    handleLanguage(val, versionValue) {
      //这里判断了一下文件式不需要带上版本号
      this.loadinDetail = true;
      this.handover = true;
      this.initComment();
      if (this.detailData.articleType == 1) {
        // this.languageValue = val;
        let params = {
          articleCode: this.detailData.articleCode,
          languageCode: val,
        };
        // getProductVersionList(params).then((res) => {
        //   this.versionOption = res.data.rows;
        //   this.versionValue =
        //     res.data.rows.length > 0 ? res.data.rows[0].id : "";
        //   let data = {
        //     articleCode: this.detailData.articleCode,
        //     productVersion: this.versionValue,
        //     languageCode: this.languageValue,
        //   };
        //   if (res.data.code == 0) {
        getVersionSwitch(params)
          .then((res) => {
            this.handover = false;
            this.loadinDetail = false;
            this.detailData = res.data.rows.articleInfo;
            this.articleTree = res.data.rows.menuList;
            // this.breadcrumbData = res.data.rows.crumbsList;
            this.$emit("getBreadcrumbData", res.data.rows.crumbsList);
            this.relevantLink = res.data.rows.recommendArticleList;
            this.fileData = res.data.rows.fileList;
            this.productVersionName = res.data.rows.articleInfo
              .productVersionName
              ? res.data.rows.articleInfo.productVersionName.split(",")
              : [];
            this.$nextTick(() => {
              this.$refs.tree?.setCurrentKey(this.detailData.articleCategory);
            });
          })
          .catch(() => {
            this.detailData = [];
            this.handover = false;
            this.loadinDetail = false;
          });
        // } else if (res.data.code == 2001) {
        //   localStorage.removeItem("library_token");
        //   localStorage.removeItem("library_userInfo");
        //   delete axios.defaults.headers["Authorization"];
        //   let url = location.href;
        //   console.log(url);
        //   this.$router.push({ name: "login", params: { url: url } });
        // } else if (res.data.code == 2003) {
        //   this.havePermission = false;
        //   this.permissionInfo =
        //     "You do not have permission to read this article";
        //   this.loadinDetail = false;
        // } else if (res.data.code == 2002) {
        //   this.havePermission = false;
        //   this.permissionInfo =
        //     "This article does not exist or has been taken down";
        //   this.loadinDetail = false;
        // }
        // getVersionSwitch(params).then((res) => {
        //   this.detailData = res.data.rows.articleInfo;
        //   this.articleTree = res.data.rows.menuList;
        //   this.breadcrumbData = res.data.rows.crumbsList;
        //   this.relevantLink = res.data.rows.recommendArticleList;
        //   this.fileData = res.data.rows.fileList;
        //   console.log(res.data.rows);
        // });
        // });
      } else {
        // this.languageValue = val;
        let params = {
          articleCode: this.articleCode,
          languageCode: val,
        };
        getProductVersionList(params).then((res) => {
          // this.versionOption = res.data.rows;
          // this.versionValue =
          //   res.data.rows.length > 0 ? res.data.rows[0].id : "";
          this.$emit("getVersion", {
            versionValue: res.data.rows.length > 0 ? res.data.rows[0].id : "",
            versionOption: res.data.rows,
          });
          let data = {
            articleCode: this.articleCode,
            productVersion: versionValue,
            languageCode: val,
          };
          getVersionSwitch(data).then((res) => {
            this.articleTree = res.data.rows.mdCatalogueList;
            if (res.data.code == 0) {
              if (this.articleTree.length > 0) {
                getById(this.articleTree[0].id)
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.detailData = res.data.rows;
                      this.$nextTick(() => {
                        this.$refs.tree?.setCurrentKey(this.detailData.id);
                      });
                      this.handover = false;
                      this.loadinDetail = false;
                    }
                  })
                  .catch(() => {
                    this.detailData = [];
                    this.handover = false;
                    this.loadinDetail = false;
                  });
              } else {
                this.detailData = [];
                this.handover = false;
                this.loadinDetail = false;
              }
            } else if (res.data.code == 2001) {
              localStorage.removeItem("library_token");
              localStorage.removeItem("library_userInfo");
              delete axios.defaults.headers["Authorization"];
              let url = location.href;
              this.$router.push({ name: "login", params: { url: url } });
            } else if (res.data.code == 2003) {
              this.havePermission = false;
              this.permissionInfo =
                "You do not have permission to read this article";
              this.loadinDetail = false;
            } else if (res.data.code == 2002) {
              this.havePermission = false;
              this.permissionInfo =
                "This article does not exist or has been taken down";
              this.loadinDetail = false;
            }
            this.detailData = res.data.rows.articleInfo;
          });
        });
      }
    },
    //切换设备版本
    handleVersion(val, languageValue) {
      this.loadinDetail = true;
      this.handover = true;
      // this.versionValue = val;
      this.initComment();
      let params = {
        articleCode: this.detailData.articleCode
          ? this.detailData.articleCode
          : this.articleCode,
        productVersion: val,
        languageCode: languageValue,
      };
      getVersionSwitch(params).then((res) => {
        if (this.articleData.articleType == 1) {
          this.detailData = res.data.rows;
          this.handover = false;
          this.loadinDetail = false;
        } else {
          this.articleTree = res.data.rows.mdCatalogueList;
          if (this.articleTree.length > 0) {
            getById(this.articleTree[0].id)
              .then((res) => {
                this.detailData = res.data.rows;
                this.$nextTick(() => {
                  this.$refs.tree?.setCurrentKey(this.detailData.id);
                });
                this.handover = false;
                this.loadinDetail = false;
              })
              .catch(() => {
                this.detailData = [];
                this.handover = false;
                this.loadinDetail = false;
              });
          } else {
            this.detailData = [];
            this.handover = false;
            this.loadinDetail = false;
          }
        }
        const { articleType } = res?.data?.rows?.articleInfo;
        this.detailData = res.data.rows.articleInfo;
        if (articleType == 1) {
          this.articleTree = res.data.rows.menuList;
          // this.breadcrumbData = res.data.rows.crumbsList;
          this.$emit("getBreadcrumbData", res.data.rows.crumbsList);
          this.relevantLink = res.data.rows.recommendArticleList;
          this.fileData = res.data.rows.fileList;
        }
      });
    },
    //初始化评论
    initComment() {
      this.commentType = 2;
      this.commentContent = "";
      this.showestimateDialog = false;
    },
    //点击推荐链接
    handleRelevantLink(id) {
      this.initComment();
      this.getDetail(id);
    },
    //控制侧边栏展开收起
    handleCollapse(index) {
      if (index == "1") {
        this.treeCollapse = this.treeCollapse == true ? false : true;
        this.showTree = this.showTree == true ? false : true;
        if (this.showTree) {
          this.treeStyle.width = "15%";
        } else {
          this.treeStyle.width = "0%";
        }
      } else {
        this.reCollapse = this.reCollapse == true ? false : true;
        this.showrelevantLink = this.showrelevantLink == true ? false : true;
        if (this.showrelevantLink) {
          this.relevantLinkStyle.width = "20%";
        } else {
          this.relevantLinkStyle.width = "0%";
        }
      }
    },

    //文章转pdf下载
    downloadPDF() {
      let params = {
        filename: this.detailData.title,
        articleId: this.detailData.articleId,
        html: `<!DOCTYPE html>
        <html lang="zh">
        <head><meta charset="utf-8" /></head>
        <body>
        ${this.detailData.content}
        </body>
        </html>`,
      };
      sendHtml(params).then((res) => {
        let blob = new Blob([res.data], { type: "application/pdf" });
        this.pdfUrl = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        var filename = this.detailData.title;
        a.href = this.pdfUrl;
        // 判断文件名是否以.pdf文件
        if (filename.endsWith(".pdf")) {
          a.download = filename;
        } else {
          a.download = filename + ".pdf";
        }
        // a.download = filename;
        a.click();
      });
    },
    //下载附件
    handleDownfile(val) {
      let params = {
        groupUrl: val.groupUrl,
      };
      this.loadinDownload = true;
      this.loadingText = "downloading";
      params = JSON.stringify(params);
      axios.defaults.headers["Content-Type"] =
        "application/json; charset=UTF-8";
      axios.defaults.responseType = "blob";
      const server = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 600000, //因文件过大，故单独设置请求超时
      });
      server
        .post("api-file/kb/filedfs/download", { groupUrl: val.groupUrl })
        .then((res) => {
          let fileType = {
            xls: "application/vnd.ms-excel",
            xlsx:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            pptx:
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            ppt: "application/vnd.ms-powerpoint",
            pdf: "application/pdf",
            docx:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            doc: "application/msword",
            txt: "text/plain",
            mp4: "video/mp4",
          };
          let blob = new Blob([res.data], { type: fileType[val.fileType] });
          this.pdfUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var filename = val.fileName;
          a.href = this.pdfUrl;
          a.download = filename;
          a.click();
          this.loadinDownload = false;
          this.loadingText = "downloading";
          if (res.data.status != "0000") {
            addDownloadsNum({ articleId: this.detailData.articleId });
          }
        });
    },
  },
  computed: {
    moment() {
      return moment;
    },
  },
  created() {
    // console.log(encodeURIComponent(Base64.encode('http://kbimg.sangfor.com/group1/M00/00/01/ChMBcGF_wRmAQO3EABezcIfcQnU826.pdf')));
    if (this.$route.query.articleData) {
      this.articleData = JSON.parse(this.$route.query.articleData);
      console.log(this.articleData);
      if (this.$route.name == "detailPage") {
        if (this.articleData.articleType == 1) {
          this.isArticle = true;
        } else {
          this.isArticle = false;
        }
        this.$emit("getIsArticle", this.isArticle);
      }
      this.getDetail(this.articleData.articleId, this.articleData.keyword);
    }
    this.showEvaluate = localStorage.getItem("library_token") ? true : false;
  },
  watch: {
    loadinDetail(val) {
      this.$emit("getLoadinDetail", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
  .noPermission {
    width: 100%;
    text-align: center;
    background: #fff;
    font-size: 24px;
    color: #9e9fb7;
    padding-top: 100px;
  }
  .structureTree {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 4px 16px rgba(113, 138, 203, 0.27);
    position: relative;
    transition: all 0.3s;
    height: 100%;
    .tree-enter-active,
    .tree-leave-active {
      transition: all 0.3s;
    }
    .tree-enter,
    .tree-leave-to {
      transform: translateX(-100px);
      opacity: 0;
    }
    .tree_Collapse {
      background: #fff;
      box-shadow: 3px 3px 6px #dae2f5;
      border-radius: 0 8px 8px 0;
      cursor: pointer;
      width: 20px;
      padding: 20px 0;
      position: absolute;
      top: 400px;
      right: -20px;
    }
    /deep/.el-tree {
      overflow-y: auto;
      height: 100%;
    }
    .el-tree /deep/ .el-tree-node__expand-icon.expanded {
      display: flex;
      height: 100%;
    }
    .el-tree /deep/ .el-tree-node__content > .el-tree-node__expand-icon {
      display: flex;
      height: 100%;
      font-size: 16px;
      font-weight: 600;
    }
    .el-tree
      /deep/
      .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background: #0d39a8 !important;
    }
    .el-tree /deep/ .el-tree-node__content {
      font-size: 16px;
      height: auto;
      // padding-top: 7px !important;
      display: flex;
      align-items: baseline;
      padding: 10px 0;
    }
    .el-tree /deep/ .el-tree-node__label {
      width: 250px;
      white-space: normal;
      display: flex;
      height: 100%;
      font-size: 16px;
      // padding-left: 8px;
    }
  }
  .content {
    margin: 0 30px;
    flex: 1;
    height: 100%;
    min-width: 0;
    .content_info {
      width: 100%;
      padding: 25px 30px;
      background: #fff;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .content_info_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 32px;
          font-weight: 600;
          .svg-icon {
            font-size: 32px;
            color: red;
            margin-left: 10px;
          }
        }
      }
      .applicabletagName {
        width: 100%;
        display: flex;
        padding: 5px 0;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .applicableVersion {
        width: 100%;
        display: flex;
        padding: 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // border-bottom: 1px solid #ccc;
        .version_title {
          padding-bottom: 5px;
          display: inline-block;
          margin-right: 10px;
        }
        .version_item {
          // width: 100px;
          margin-right: 10px;
          color: #71b32f;
          // width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .content_info_data {
        width: 100%;
        display: flex;
        padding: 15px 0;
        .postTime {
          font-size: 18px;
          color: #ababae;
        }
        .downloads {
          font-size: 18px;
          color: #ababae;
          margin-left: 35px;
          .svg-icon {
            font-size: 18px;
            color: #0d39a8;
            margin-right: 10px;
          }
        }
        .hits {
          font-size: 18px;
          color: #ababae;
          margin-left: 35px;
          .svg-icon {
            font-size: 18px;
            color: #0d39a8;
            margin-right: 10px;
          }
        }
      }
      .content_info_text {
        width: 100%;
        margin-bottom: 40px;
        /deep/#markdownText {
          word-break: break-word;
          li {
            white-space: pre-wrap;
          }
          blockquote {
            color: #666;
            border-left: 4px solid #ddd;
            padding-left: 20px;
            margin-left: 0;
            font-size: 14px;
            font-style: italic;
          }
          h1 {
            padding: 10px 0;
          }
          h2 {
            padding: 10px 0;
          }
          h3 {
            padding: 10px 0;
          }
          h4 {
            padding: 10px 0;
          }

          img {
            max-width: 100%;
            height: auto;
            cursor: zoom-in;
          }
          font {
            white-space: initial !important;
            ignore_js_op {
              line-height: 0;
            }
          }
        }
        p {
          // font-size: 16px;
          line-height: 36px;
          color: #4d4e52;
          margin-top: 15px;
        }
      }
      .content_info_video {
        width: 100%;
        margin-bottom: 40px;
        .video_box {
          width: fit-content;
          padding: 10px;
          background: #fff;
          .video_js {
            width: 620px;
            height: 420px;
            border-radius: 6px;
          }
        }
      }
      .content_info_img {
        width: 100%;
        padding-bottom: 40px;
        .img_box {
          width: 620px;
          .img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .content_info_filedown {
        width: 100%;
        margin-bottom: 50px;
        .filedown_item {
          width: 100%;
          padding: 20px;
          display: flex;
          align-items: center;
          background: #f2f6ff;
          border-radius: 6px;
          margin-bottom: 20px;
          .iconImg {
            width: 3%;
            margin-right: 15px;
            .svg-icon {
              font-size: 34px;
            }
          }
          .fileName {
            cursor: pointer;
            width: 93%;
            font-size: 16px;
          }
          .iconDonw {
            cursor: pointer;
            width: 3%;
            margin-left: 10px;
            .svg-icon {
              font-size: 20px;
              color: #0d39a8;
            }
          }
        }
      }
      .jumpArticleMsg {
        width: 100%;
        text-align: center;
        padding: 50px 0;
        p {
          font-size: 18px;
          color: #e6a23c;
        }
      }
      .evaluate {
        width: fit-content;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;
        padding: 60px 0 220px 0;
        .Feedback {
          cursor: pointer;
          border-radius: 6px 0 0 6px;
          font-size: 16px;
          padding: 15px 16px;
          color: #7a7b80;
        }
        .evaluate_item {
          display: flex;
          border: 1px solid #d4dff5;
          border-radius: 25px;
          background: #fff;
          align-items: center;
          .bad {
            cursor: pointer;
            font-size: 16px;
            padding: 15px 25px;
            border-radius: 0 25px 25px 0;
            .svg-icon {
              font-size: 20px;
              color: #f4a03e;
              margin: 0 5px 0 0;
            }
          }
          .good {
            border-radius: 25px 0 0 25px;
            cursor: pointer;
            border-right: 1px solid #d4dff5;
            font-size: 16px;
            padding: 15px 18px;
            .svg-icon {
              font-size: 20px;
              color: #f4a03e;
              margin: 0 5px 0 0;
            }
          }
          .active {
            background: #5580ee;
            color: white;
            .svg-icon {
              color: white;
            }
          }
        }
        .estimate_dialog {
          width: 100%;
          background: #fff;
          position: absolute;
          top: 120px;
          left: 0;
          border-radius: 4px;
          padding: 12px;
          box-shadow: 0px 3px 12px rgba(183, 197, 234, 0.53);
          .estimate_dialog_title {
            width: 100%;
            color: #2d2f36;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 14px;
          }
          .estimate_dialog_text {
            width: 100%;
            border-color: #dedede;
            height: 100px;
            resize: none;
            border-radius: 4px;
            outline: none;
            padding: 12px;
            font-size: 14px;
            color: #4d4e52;
          }
          .estimate_dialog_btn {
            width: 100%;
            background: #5580ee;
            color: #fff;
            font-size: 16px;
            margin-top: 5px;
          }
        }
        .estimate-enter-active,
        .estimate-leave-active {
          transition: opacity 0.3s;
        }
        .estimate-enter,
        .estimate-leave-to {
          opacity: 0;
        }
      }
    }
  }
  .relevantLink {
    background: #f2f6ff;
    position: relative;
    transition: all 0.3s;
    height: 100%;
    .relevantLink_box {
      width: 100%;
      height: 100%;
      .relevantLink_info {
        width: 100%;
        padding: 0 28px 0 28px;
        height: 100%;
        overflow-y: auto;
        background: #fff;
        .info_item {
          width: 100%;
          border-bottom: 1px solid #ccc;
          .info_item_title {
            margin-top: 30px;
            width: 100%;
            font-size: 18px;
            color: #2d2f36;
            cursor: pointer;
            word-break: break-all;
            .svg-icon {
              color: red;
              font-size: 18px;
            }
          }
          .info_item_data {
            width: 100%;
            display: flex;
            padding: 20px 0 30px 0;
            .postTime {
              width: 65%;
              font-size: 16px;
              color: #7a7b80;
            }
            .downloads {
              width: 17.5%;
              font-size: 16px;
              color: #ababae;
              .svg-icon {
                margin-right: 5px;
                color: #0d39a8;
                font-size: 16px;
              }
            }
            .pageview {
              width: 17.5%;
              font-size: 16px;
              color: #ababae;
              .svg-icon {
                margin-right: 5px;
                color: #0d39a8;
                font-size: 16px;
              }
            }
          }
        }
        .iconBox {
          width: fit-content;
          margin: 0 auto;
          text-align: center;
          .svg-icon {
            font-size: 80px;
          }
          .nodata {
            text-align: center;
          }
        }
      }
    }
    .relevantLink_Collapse {
      background: #fff;
      box-shadow: -3px 3px 6px #dae2f5;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      width: 20px;
      padding: 20px 0;
      position: absolute;
      top: 400px;
      left: -20px;
    }
    .relevant-enter-active,
    .relevant-leave-active {
      transition: all 0.3s;
    }
    .relevant-enter,
    .relevant-leave-to {
      transform: translateX(100px);
      opacity: 0;
    }
  }
}
</style>
